.Background {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
}

.ModalWrapper {
  width: 800px;
  height: auto;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  position: relative;

  top: 5%;
  left: 20%;
  z-index: 10;
  border-radius: 10px;

  justify-content: center;
}

.ModalImg {
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: #000;
}
.ModalImg img {
  height: 100%;
  width: 100%;
}

.ModalContent {
  display: flex;
  color: #141414;
  height: 100%;
}

.modal_information {
  width: 50%;
}

p {
  margin-bottom: 1rem;
}

button {
  padding: 10px 24px;
  background: #141414;
  color: #fff;
  border: none;
}

.CloseModalButton {
  cursor: pointer;

  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
}

.modal_image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
}

.Image_Modal {
  z-index: 999;
  position: relative;
  height: 250px;
  margin-bottom: 25px;
}
.Image_Modal img {
  height: 110%;
}

.pin_style {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 30px;
  left: -50px;
  z-index: 1;
  transform: rotate(90deg);
}
.pins {
  transform: rotate(90deg);
}
.pin_style > img {
  height: 100%;
  width: 100%;
}
