.Background {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding-top: 0px;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
}

.ModalWrapper {
  width: 900px;
  height: 540px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  position: relative;

  top: 5%;
  left: 15%;
  z-index: 10;
  border-radius: 10px;
  padding: 5px;
  justify-content: center;
  align-items: center;
}

.ModalImg {
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: #000;
}
.ModalImg img {
  height: 100%;
  width: 100%;
}

.ModalContent {
  display: flex;
  line-height: 1.8;
  color: #141414;
  margin-top: 50px;
  padding: 20px;
}

p {
  margin-bottom: 1rem;
}

button {
  padding: 10px 24px;
  background: #141414;
  color: #fff;
  border: none;
}

.CloseModalButton {
  cursor: pointer;

  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
}
