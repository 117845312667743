.pin_style {
  height: 20px;
  width: 20px;
  position: absolute;

  left: 20px;
  z-index: 1;
  transform: rotate(90deg);
}
.pin_style > img {
  height: 100%;
  width: 100%;
}
