.FooterContainer {
  background-color: #101522;
}

.FooterWrapper {
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
}

.SocialMedia {
  max-width: 1000px;
  width: 100%;
}
.SocialMediaWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;
}

.SocialLogo {
  color: #fff;
  justify-self: start;

  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
}

.SocialLogo Link {
  cursor: pointer;
  text-decoration: none;
}

.WebsiteRights {
  color: #fff;
  margin-bottom: 16px;
}

.SocialIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.SocialIconLink {
  color: #fff;
  font-size: 24px;
}
.FooterImage {
  width: 50px;
  height: 50px;
  margin-bottom: 30px;
}
.FooterImage img {
  height: 100%;
  width: 100%;
}
