.Background1 {
  display: block;
  position: fixed;
  left: 410px;
  top: 70px;
  z-index: 9999;
  width: 50%;
  height: 50%;
  padding-top: 50px;
  overflow: auto;
  transition: all 0.3s linear;
}

.Background2 {
  display: block;
  position: absolute;
  top: 0;
  left: 40px;
  z-index: 9999;
  width: 50%;
  height: 50%;
  transition: all 0.3s linear;
}

.ModalWrapper1 {
  width: 150px;
  height: auto;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  position: relative;

  top: 5%;
  left: 15%;
  z-index: 10;
  border-radius: 10px;
  padding: 5px;
  justify-content: center;
  align-items: center;
}

.ModalContent {
  display: flex;
  line-height: 1.8;
  color: #141414;
  margin-top: 50px;
  padding: 20px;
}

p {
  margin-bottom: 1rem;
}

button {
  padding: 10px 24px;
  background: #141414;
  color: #fff;
  border: none;
}

.CloseModalButton {
  cursor: pointer;

  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
}
