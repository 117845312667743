.section3 {
  margin-top: 4rem;
  height: auto;
  width: 100%;
}
.cross {
  display: flex;
  flex-direction: row;
  margin-bottom: 4rem;
  height: 70vh;
}
.cross1 {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 4rem;
  height: 70vh;
}
.product-text {
  width: 50%;
  margin-bottom: 15px;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-text2 {
  max-width: 500px;
  align-self: center;
}
.product-text2 p {
}

.image-product {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 15px;
}
.image-product img {
  height: auto;
  width: 70%;
}

@media screen and (max-width: 500px) {
  .cross {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    height: auto;
  }
  .cross1 {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    height: auto;
  }
  .product-text {
    width: 100%;
    margin-bottom: 15px;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-product {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 15px;
  }
  .image-product img {
    height: auto;
    width: 60%;
  }
}
