.main_slider {
  height: 100vh;
}
.box0 {
  height: 100vh;
  margin: 0;
  padding: 0;
}
.box {
  width: 300px;
  height: 340px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  transform: translate(-50%, -50%);
  top: 40%;
  left: 50%;
  margin-right: 20px;
}

.slide-img {
  height: 250px;
  position: relative;
}

.slide-img img {
  width: 100%;
  height: 100%;
}

.detail-box {
  width: 100%;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  font-family: calibiri;
}
.type {
  display: flex;
  flex-direction: column;
}

.type a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.type button {
  color: #222222;
  margin: 5px 0;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 15px 6px;
  outline: none;
}

.type span {
  color: rgba(26, 26, 26, 0.5);
}

.price {
  color: #3333;
  font-weight: 600;
  font-size: 1.1rem;
  font-family: poppins;
  letter-spacing: 0.5px;
}

.overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(92, 95, 236, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.buy-btn {
  width: 160px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
  color: #252525;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: calibri;
  border-radius: 20px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.buy-btn:hover {
  color: #ffff;
  background-color: #f15af3;
  transition: all ease 0.3s;
}

.overlay {
  visibility: hidden;
}

.slide-img:hover .overlay {
  visibility: visible;
}

.slider2 {
  display: flex;
  justify-content: center;
  overflow: hidden;
  animation: 24s slider infinite;
}

@keyframes slider2 {
  0% {
    left: 0%;
  }
  20% {
    left: 0;
  }
  25% {
    left: -100%;
  }
}
