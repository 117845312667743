.Application {
  display: flex;
}

.Sidebar {
  width: 28%;
  height: 130vh;
  background-color: white;
  margin-bottom: 50px;
}

.description-section {
  width: 70%;
  height: 100%;
}

.chromosome_head {
  height: 10%;
  background-color: #63c5da;

  display: flex;
  align-items: center;
  justify-content: center;
}
.chromosome_button {
  padding: 15px;
}

.fas fa-plus-square {
  background-color: #009879;
}

.sidebar-head {
  background-color: #009879;
  height: 10%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
}
.sidebar-head button {
  width: 90%;
  outline: none;
  border: none;
  padding: 15px 25px;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.sidebar-search {
  height: 10%;
  background-color: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-search form {
  width: 100%;
}
.sidebar-search input[type="text"] {
  width: 85%;
  padding: 10px;
  border-radius: 40px;
  outline: none;
  border: none;
}

.sidebar-main-content {
  background-color: white;
  cursor: pointer;
}

.sidebar-selected-items {
  height: 20%;
  background-color: red;
  cursor: pointer;
}
.sidebar-main-content ul {
  list-style: none;
  cursor: pointer;
}

.sidebar-main-content li {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #696969;
  border-radius: 5px;
  margin-bottom: 2px;
}

.table {
  margin-top: 20px;
  overflow: scroll;
  height: 500px;
  color: #6a5d5d;
  cursor: pointer;
  font-family: sans-serif;
}

.table tr {
  display: flex;
  justify-content: space-between;

  font-family: sans-serif;
}
.table td {
  padding: 0.5rem;
  cursor: pointer;
  font-family: sans-serif;
}

.table tr:nth-of-type(odd) {
  background-color: #f3f2f8;
}

.chromosome_image {
  height: 300px;
  width: 300px;
}

.chrom {
  height: 100%;
  width: 100%;
}
.chromosome {
  width: 100%;
  background-color: lightgrey;
  margin-top: 50px;
  background-color: white;
}

.chromosome_body {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-left: 10px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.chromosome_box1 {
}

.chromosome_box {
  margin-top: 20px;
  height: 300px;
  width: 70px;
  margin-bottom: 25px;
  padding-top: 20px;
  background-color: #f3f3f3;
  background-color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chromosome_box > img {
  height: 80%;
  width: 40%;
}

.image_c {
  height: 100%;
  width: 40%;
  margin-bottom: 25px;
  position: relative;
}
.image_c img {
  height: 110%;
}
.h1_c {
  height: 100%;
  width: 40%;
}
.h1_c h1 {
  width: 100%;
  height: 100%;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 800px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f2f8;
}

.styled-table tbody tr:nth-of-type(odd) {
  background-color: white;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.geneInformation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card {
  margin-top: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  font-family: sans-serif;
}

.card_box {
  width: 86%;
}

.table-gene {
  width: 86%;
  font-family: sans-serif;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container1 {
}

.card-header {
  width: 99%;
  padding-left: 10px;
  margin: 0;
}

.card_info {
  padding-left: 10px;
  padding-bottom: 10px;
  display: inline;
  font-size: 18px;
  height: 30px;
  padding-top: 12px;
  text-align: "left";
  width: 100%;
}

.card_span {
  padding-left: 10px;
  padding-bottom: 5px;
}
.pin_style {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 30px;
  left: -50px;
  z-index: 1;
  transform: rotate(90deg);
}
.pin_style > img {
  height: 100%;
  width: 100%;
}
.pins {
  transform: rotate(90deg);
}
@media only screen and (max-width: 1200px) {
  .description-section {
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: 850px) {
  .Application {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .Sidebar {
    width: 100%;

    margin-top: 20px;
    height: 130vh;
    background-color: white;
    align-items: center;
    border: 1px solid grey;
  }

  .description-section {
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .Application {
    display: flex;
    flex-direction: column;
    max-width: none;
    text-align: center;
    justify-content: center;
  }

  .Sidebar {
    width: 140%;
    margin-top: 20px;
    height: 130vh;
    background-color: white;
    align-items: center;
    border: 1px solid grey;
  }

  .description-section {
    height: 100%;
    width: 100%;
    margin-left: 150px;
  }
}
@media only screen and (max-width: 400px) {
  .Application {
    display: flex;
    flex-direction: column;
    max-width: none;
    text-align: center;
    justify-content: center;
  }

  .Sidebar {
    width: 200%;
    margin-top: 20px;
    height: 130vh;
    background-color: white;
    align-items: center;
    border: 1px solid grey;
  }

  .description-section {
    height: 100%;
    width: 100%;
    margin-left: 200px;
  }
}
@media only screen and (max-width: 300px) {
  .Application {
    display: flex;
    flex-direction: column;
    max-width: none;
  }

  .Sidebar {
    width: 270%;
    margin-top: 20px;
    height: 130vh;
    background-color: white;
    align-items: center;
    border: 1px solid grey;
    margin-bottom: 100px;
  }

  .description-section {
    height: 100%;
    width: 100%;
    margin-left: 250px;
  }
}
@media only screen and (max-width: 200px) {
  .Application {
    display: flex;
    flex-direction: column;
    max-width: none;
  }

  .Sidebar {
    width: 380%;
    margin-top: 20px;
    height: 130vh;
    background-color: white;
    align-items: center;
    border: 1px solid grey;
    margin-bottom: 100px;
  }

  .description-section {
    height: 100%;
    width: 100%;
    margin-left: 300px;
  }
}
