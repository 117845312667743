.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  margin-bottom: -80px;
}

.step {
  padding: 20px;
  border: 2px solid white;
  font-size: 20px;
  background-color: #01bf71;
  border-radius: 4px;
  color: white;
}

.step link {
  text-decoration: none;
}
.step:hover {
  background-color: #242424;
}
