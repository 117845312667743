.heading {
  height: 60vh;
  width: 100%;
  background-image: url("../../assets/images/genome.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading h1 {
  color: white;

  font-size: 40px;
}
