video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.body-container {
  background: url("../../assets/images/genome.jpg") center center/cover
    no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.body-container > .H_container > h1 {
  color: #fff;
  font-size: 100px;

  font-family: Arial, Helvetica, sans-serif;
}

.body-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucid Sans Unicode", "Lucida Grande", Arial,
    Helvetica, sans-serif;
}

.body-btns {
  margin-top: 32px;
}

.body-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 700px) {
  .body-container > .H_container > h1 {
    color: #fff;
    font-size: 50px;

    font-family: Arial, Helvetica, sans-serif;
  }
}
