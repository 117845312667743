.carousel-container {
  height: 100vh;
  margin-top: 50px;
}
.carousel-container h1 {
  margin-bottom: 30px;
}

.carousel {
  height: 80vh;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.carouselInner {
  height: 90%;
  width: 95%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
}
.carouselInner img {
  height: 100%;
  width: 100%;
}

.carouselInner .left-c {
  flex: 5%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.3);
  display: grid;
  place-items: center;
  color: white;
  cursor: pointer;
}

.carouselInner .right-c {
  flex: 5%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.3);
  display: grid;
  place-items: center;
  color: white;
  cursor: pointer;
}
.carouselInner .center-c {
  flex: 80%;
  height: 100%;
}
